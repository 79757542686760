<template>
	<div>
		<b-button-toolbar class="bg-secondary">
			<b-button-group>
				<!-- FILE -->
				<b-dropdown right text="File" class="custom-shadow">
					<input type="file" @change="callLoadFile($event.target.files[0])" ref="loadFile" style="display: none" accept=".ddpe">
					<b-dropdown-item :disabled="!dupes" @click="save()">Save progress</b-dropdown-item>
					<!-- <b-dropdown-item>Save As</b-dropdown-item> -->
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item :disabled="!dupes" @click="showModal(true)" v-b-modal.download-modal>Save results</b-dropdown-item>
					<!-- <b-dropdown-item>Export As</b-dropdown-item> -->
					<b-dropdown-divider></b-dropdown-divider>
					<b-dropdown-item :disabled="!dupes" @click="close">
						Clear
					</b-dropdown-item>
				</b-dropdown>

				<!-- Edit -->
				<b-dropdown right text="Edit" class="custom-shadow">
					<b-dropdown-item @click="undo()" :disabled="!canUndo">
						<b-icon-arrow-counterclockwise /> Undo
						<span class="text-muted">(Ctrl+Z)</span>
					</b-dropdown-item>
					<b-dropdown-item @click="redo()" :disabled="!canRedo">
						<b-icon-arrow-counterclockwise /> Redo
						<span class="text-muted">(Ctrl+Y)</span>
					</b-dropdown-item>
				</b-dropdown>

				<!-- View -->
				<b-dropdown right text="Display Columns" class="custom-shadow">
					<b-dropdown-group header="Table Fields (drag and drop)">
						<TableColumnPicker />
					</b-dropdown-group>
				</b-dropdown>

				<!-- HELP -->
				<!--<b-button @click="openHelp">Help</b-button>-->
				<b-button disabled v-if="dupes && !isNaN(dedupedRefs.length)">Singulars kept: {{dedupedRefs.length}}</b-button>
				<b-button disabled v-if="dupes && !isNaN(dedupedRefs.length)">Duplicates removed: {{dupes.flat(2).length - dedupedRefs.length}}</b-button>
			</b-button-group>
		</b-button-toolbar>

		<!-- EXPORT MODAL -->
		<b-modal v-if="isShowModal" @close="showModal(false)" id="download-modal" title="Save" :hide-footer="true">
			<b-row class="mb-4">
				<b-col cols="7"><span>All References Deduplicated ({{dedupedRefs.length}})</span></b-col>
				<b-col><b-button variant="success" @click="callDownload(dedupedRefs, 'deduplicated')">Save</b-button></b-col>
			</b-row>
			<hr />
			<b-row class="mb-4">
				<b-col cols="7"><span>All References ({{allRefs().length}})</span></b-col>
				<b-col><b-button @click="callDownload(allRefs(), 'deduplicated-plus-dupes')">Save</b-button></b-col>
			</b-row>
			<b-row class="mb-4">
				<b-col cols="7"><span>Removed Duplicate References ({{removedDuplicates().length}})</span></b-col>
				<b-col><b-button @click="callDownload(removedDuplicates(), 'deduplicated-dupes')">Save</b-button></b-col>
			</b-row>
			<b-row class="mb-4">
				<b-col cols="7"><span>Removed Screened References ({{removedScreened().length}})</span></b-col>
				<b-col><b-button @click="callDownload(removedScreened(), 'deduplicated-screened')">Save</b-button></b-col>
			</b-row>
			<b-row>
				<b-col cols="7"><span>All Removed References ({{removedAll().length}})</span></b-col>
				<b-col><b-button @click="callDownload(removedAll(), 'deduplicated-removed')">Save</b-button></b-col>
			</b-row>
		</b-modal>
	</div>
</template>

<script>
import exportMixin from "../mixins/exportMixin";
import importMixin from "../mixins/importMixin";
import loadMixin from "../mixins/loadMixin";
import saveMixin from "../mixins/saveMixin";

import TableColumnPicker from "./TableColumnPicker.vue";

import { mapGetters } from "vuex";

import { BIconArrowCounterclockwise } from "@iebh/bootstrap-vue";

export default {
	name: 'TheToolbar',
	mixins: [
		exportMixin,
		importMixin,
		loadMixin,
		saveMixin,
	],
	components: {
		BIconArrowCounterclockwise,
		TableColumnPicker
	},
	computed: {
		...mapGetters({
			dupes: 'duplicates/getDupes',
			canUndo: 'duplicates/canUndo',
			canRedo: 'duplicates/canRedo'
		}),
	},
	data() {
		return {
			isShowModal: false,
		}
	},
	mounted() {
		// Event listener for undo/redo keys
		window.addEventListener('keydown', e => {
			if (e.code == "KeyZ" && e.ctrlKey && this.canUndo) {
				this.undo();
			} else if (e.code == "KeyY" && e.ctrlKey && this.canRedo) {
				this.redo();
			}
		});
	},
	methods: {
		callLoadFile(e) {
			this.loadFile(e);
			this.$refs.loadFile.value = null;
		},
		callImportFile(e) {
			this.importFiles([e]);
			this.$refs.importFile.value = null;
		},
		showModal(isShow) {
			this.isShowModal = isShow;
		},
		undo() {
			this.$store.dispatch('duplicates/undo');
		},
		redo() {
			this.$store.dispatch('duplicates/redo');
		},
		close() {
			this.$root.$emit('show-warning-modal');
		},
		/*openHelp() {
			window.open("https://tera-tools.com/help/deduplicator", "_blank");
		}*/
	}
}
</script>

<style>
.dropdown.custom-shadow .dropdown-menu {
	box-shadow: 5px 5px 15px 0px rgba(0,0,0,0.5) !important;
}
</style>
